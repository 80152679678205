@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
	@media print {
		#material {
			display: none;
		}
	}

	font-family: 'Poppins', sans-serif;
}
.pdf {
	transform: scale(0.75);
	position: absolute;
	top: -105px;
	left: -180px;
	overflow: hidden;
	background-color: '#112233';
}

.wrap {
	overflow-x: hidden !important;
	overflow-y: hidden !important;
	background-color: '#1A202';
}
.react-pdf__Page__textContent {
	display: none;
}
.react-pdf__Page__annotations {
	display: none;
}

@media print {
	.pdf {
		display: none;
	}
}
